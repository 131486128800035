import logo from '../icons/footer-logo.svg';
import copy from '../copy.json';

function Footer() {
  // Create a new Date object
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const links = [];

  for (const [index, link] of copy.footer.links.entries()) {
    links.push(
      <a href={ link.url } target="_blank" rel="noreferrer" className="link" key={ index }>
        { link.title }
      </a>
    );
  }

  return (
    <div className="footer">
      <div className="main-footer">
        <div className="logo-container">
          <img src={logo} className="footer-logo" alt="logo" />
        </div>

        { links.length > 0 && ( <div className="links-container">{ links }</div> )}
      </div>

      <div className="footer-copyright">
        <span className="copyright">Copyright. © { currentYear } { copy.footer.copyright }</span>
      </div>
    </div>
  );
}

export default Footer;
