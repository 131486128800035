import Header from './components/Header';
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import CallNow from "./components/CallNow";
import Socials from "./components/Socials";
import FAQ from "./components/FAQ";

function App() {
    return (
        <div className="app">
          <Header/>

          <main className="main">
            <Hero/>

            <CallNow/>

            <Socials/>

            <FAQ/>
          </main>

          <Footer/>
        </div>
    );
}

export default App;
