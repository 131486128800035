import copy from '../copy.json';
import arrow from '../icons/arrow.svg';

function FAQ() {
  const faqs = [];
  const allowMultipleOpen = false;

  for (const [index, faq] of copy.faq.faqs.entries()) {
    faqs.push(
      <li key={ index } className={index === 0 ? "open" : ""}>
        <div className="trigger" onClick={ triggerFAQ }>
          <h3 className="question">{ faq.question }</h3>

          <img className="arrow" src={ arrow } alt="Arrow" />
        </div>

        <p className="answer" style={{ height: index === 0 ? 'auto': '0px' }} dangerouslySetInnerHTML={{
            __html: faq.answer
        }}></p>
      </li>
    );
  }

  function triggerFAQ(e) {
    const faq = e.target.closest('li');
    const answer = faq.querySelector('.answer');

    if (faq) {
      // Close all other open FAQs.
      if (!allowMultipleOpen) {
        const openFAQs = document.querySelectorAll('.faqs__container li.open');

        for (const openFAQ of openFAQs) {
          if (openFAQ !== faq) {
            openFAQ.classList.remove('open');
            openFAQ.querySelector('.answer').style.height = "0px";
          }
        }
      }

      // Give the answer a height if it's not already set, so we can transition it.
      if (faq.classList.contains('open') && answer.style.height === "auto") {
        answer.style.height = answer.scrollHeight + 'px';

        // Force a reflow to trigger the CSS transition.
        void answer.offsetHeight;
      }

      faq.classList.toggle('open');

      answer.style.height = faq.classList.contains('open') ? answer.scrollHeight + 'px' : "0px";
    }
  }

  return (
    <div className="faqs" id="faqs">
      <div className="faqs__wrapper">
        <h2 className="heading">{ copy.faq.heading }</h2>

        { faqs.length > 0 && (
          <ul className="faqs__container">
              { faqs }
          </ul>
        )}
      </div>
    </div>
  );
}

export default FAQ;
