import copy from '../copy.json';

import Marquee from "react-fast-marquee";

function Hero() {
  return (
    <div className="hero">
      <Marquee autoFill={true} className='top' direction={'right'}>
        { copy.hero.marquee }
      </Marquee>

      <div className="hero__wrapper">
        <div className="hero-image__container">
          <img src="/images/hero.png" alt={ copy.hero["image-alt"] }/>
        </div>

        <div className="copy-section">
          <h1 className="heading">
            <p dangerouslySetInnerHTML={{ __html: copy.hero.title }}></p>
            <p className="subtitle" dangerouslySetInnerHTML={{ __html: copy.hero.subtitle }}></p>
          </h1>

          <h2 className="subheading">{ copy.hero.subheading }</h2>
        </div>
      </div>

      <Marquee autoFill={true}>
        { copy.hero.marquee }
      </Marquee>
    </div>
  );
}

export default Hero;
