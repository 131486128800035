import copy from "../copy.json";

function Socials() {
  const socials = [];

  for (const [index, icon] of copy.socials.icons.entries()) {
    socials.push(
        <li key={ index }>
          <a href={ icon.url } target='_blank' rel='noreferrer'>
            <img src={ icon.src } alt={ icon.title } />
          </a>
        </li>
    );
  }

  return (
    <div className="socials">
      <div className="socials__wrapper">
        <h2 className="title" dangerouslySetInnerHTML={{
          __html: copy.socials.title
        }}></h2>

        <p className="hashtag">{ copy.socials.hashtag }</p>

        { socials.length > 0 && (
          <ul className="icons__container">
            { socials }
          </ul>
        )}
      </div>
    </div>
  );
}

export default Socials;
