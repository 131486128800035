import logo from '../icons/logo.svg';
import menu from '../icons/menu.svg';
import copy from '../copy.json';

function Header() {
  const links = [];

  for (const [index, link] of copy.header.links.entries()) {
    links.push(
      <li className={link?.special && "special"} key={ index }>
        { link?.special ? (
          <a href={ link.url } rel="noreferrer" className="link" data-location="blue-button">
            { link.title }
          </a>
        ) : (
          <a href={ link.url } rel="noreferrer" className="link">
            { link.title }
          </a>
        ) }

      </li>
    );
  }

  return (
      <div className="header">
        <div className="header-wrapper">
          <div className="logo-container">
            <img src={logo} className="header-logo" alt="logo" />
          </div>

          { links.length > 0 && (
            <nav className="nav-container">
              <ul className="nav-list">
                { links }
              </ul>

              <div className="nav-buttons">
                <button className="nav-button">
                    <img src={menu} className="nav-menu" alt="menu" />
                </button>
              </div>
            </nav>
          )}
        </div>
      </div>
  );
}

export default Header;
