import Dots from '../icons/dots.svg';
import copy from '../copy.json';
import ZipCodes from "./ZipCodes";

function CallNow() {
  const icons = [];

  for (const [index, icon] of copy.callNow.icons.entries()) {
    icons.push(
      <li key={ index }>
        <img src={ icon.src } alt={ icon.title } />
      </li>
    );
  }

  return (
    <div className="call-now" id="call">
      <div id='call-now' />

      <div className="call-now__wrapper">
        <h2 className="heading">{ copy.callNow.heading }</h2>

        <ZipCodes/>

        { icons.length > 0 && (
            <ul className="icons__container">
              { icons }
            </ul>
        )}

        <div className="dots v2">
          <img src={ Dots } alt="Dots" />
        </div>
      </div>

      <div className="dots v1">
        <img src={ Dots } alt="Dots" />
      </div>
    </div>
  );
}

export default CallNow;
